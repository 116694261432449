<template>
    <canvas id="canvas-confetti"></canvas>
</template>

<script setup>
import random from 'lodash/random'
import pull from 'lodash/pull'
import uniqueId from 'lodash/uniqueId'
import {useWebsockets} from "@/store/websockets"
import {useAuthStore} from "~/store/auth";

const websockets = useWebsockets()
const authStore = useAuthStore()

const DECAY = 5;
const SPREAD = 50;
const GRAVITY = 1200;
const vueCtx = ref({})
const vueCanvas = ref({})
const dpr = ref(1)

let confettiSpriteIds = [];
let confettiSprites = {};

//property is set in header component
const muted = useLocalStorage('muted', false)

watch(() => muted.value, (newValue) => {
    if (!audio.value || !newValue) return
    audio.value.pause()
})

const audio = ref(new Audio())

useEventBus('trigger').on((data) => {
    triggerSound(data)
})

useEventBus('organisationTrigger').on((data) => {
    prepareConfetti()
})

useEventBus('registrationConfirm').on((data) => {
    prepareConfetti()
})

useEventBus('confetti').on((data) => {
    prepareConfetti()
})

onMounted(() => {
    vueCanvas.value = document.getElementById("canvas-confetti")

    if (vueCanvas.value) {
        dpr.value = window.devicePixelRatio || 1
        vueCtx.value = vueCanvas.value.getContext("2d")
        vueCtx.value.scale(dpr.value, dpr.value)

        setCanvasSize()
        vueCanvas.value.addEventListener('resize', setCanvasSize)

        setTimeout(() => {
            if (useRoute().query.celebrate) prepareConfetti()
        }, 1000)
    }
})

function triggerSound(data) {
    if(data.causer_id === authStore.userData.id && data.device_type === 'mobile' || !data.sound_path) return
    audio.value.src = '/storage/' + data.sound_path
    audio.value.addEventListener('canplaythrough', () => {
        if(!muted.value) audio.value.play()
    })
}

function prepareConfetti() {
    useNuxtApp().$gsap.ticker.add(render);
    addConfettiParticles(1000, -90, 5000, vueCanvas.value.width * 0.5, vueCanvas.value.height);
    shootConfetti();
}

function setCanvasSize() {
    vueCanvas.value.width = window.innerWidth * dpr.value;
    vueCanvas.value.height = window.innerHeight * dpr.value;
}

function addConfettiParticles(amount, angle, velocity, x, y) {

    let i = 0;
    while (i < amount) {
        // sprite
        const r = random(4, 6) * dpr.value;
        const d = random(15, 25) * dpr.value;

        const cr = random(50, 255);
        const cg = random(50, 200);
        const cb = random(50, 200);
        //const color = `rgb(${cr}, ${cg}, ${cb})`;
        //const color = `rgb(239,66,111)`;
        //const cr = random(229, 249);
        //const cg = random(56, 76);
        //const cb = random(101, 121);
        //const color = `rgb(${cr}, ${cg}, ${cb})`;
        //const hue = random(190, 330);
        //const color = `hsl(${hue},100%,50%)`

        const colors = ['#000000', '#F5FE46']
        const color = colors[random(0, 1)]

        const tilt = random(10, -10);
        const tiltAngleIncremental = random(0.07, 0.05);
        const tiltAngle = 0;

        const id = uniqueId();
        const sprite = {
            [id]: {
                angle,
                velocity,
                x,
                y,
                r,
                d,
                color,
                tilt,
                tiltAngleIncremental,
                tiltAngle,
            },
        };

        Object.assign(confettiSprites, sprite);
        confettiSpriteIds.push(id);
        tweenConfettiParticle(id);
        i++;
    }
}

function tweenConfettiParticle(id) {

    const minAngle = confettiSprites[id].angle - SPREAD / 2;
    const maxAngle = confettiSprites[id].angle + SPREAD / 2;

    const minVelocity = confettiSprites[id].velocity / 4;
    const maxVelocity = confettiSprites[id].velocity;

    // Physics Props
    const velocity = random(minVelocity, maxVelocity);
    const angle = random(minAngle, maxAngle);
    const gravity = GRAVITY;
    // const friction = random(0.1, 0.25);
    const friction = random(0.01, 0.05);
    const d = 0;

    useNuxtApp().$gsap.to(confettiSprites[id], DECAY, {
        physics2D: {
            velocity,
            angle,
            gravity,
            friction,
        },
        d,
        ease: 'power4.in',
        onComplete: () => {
            // remove confetti sprite and id
            pull(confettiSpriteIds, id);
            delete confettiSprites[id];
            if (confettiSpriteIds.length === 0) {
                // remove renderer when confetti faded out
                setTimeout(() => {
                    useNuxtApp().$gsap.ticker.remove(render);
                }, 500);
            }
        },
    });
}

function updateConfettiParticle(id) {
    const sprite = confettiSprites[id];

    const tiltAngle = 0.0005 * sprite.d;

    sprite.angle += 0.01;
    sprite.tiltAngle += tiltAngle;
    sprite.tiltAngle += sprite.tiltAngleIncremental;
    sprite.tilt = (Math.sin(sprite.tiltAngle - (sprite.r / 2))) * sprite.r * 2;
    sprite.y += Math.sin(sprite.angle + sprite.r / 2) * 2;
    sprite.x += Math.cos(sprite.angle) / 2;
}

function drawConfetti() {
    confettiSpriteIds.map(id => {
        const sprite = confettiSprites[id];

        vueCtx.value.beginPath();
        vueCtx.value.lineWidth = sprite.d / 2;
        vueCtx.value.strokeStyle = sprite.color;
        vueCtx.value.moveTo(sprite.x + sprite.tilt + sprite.r, sprite.y);
        vueCtx.value.lineTo(sprite.x + sprite.tilt, sprite.y + sprite.tilt + sprite.r);
        vueCtx.value.stroke();

        updateConfettiParticle(id);
    });
}

function render() {
    vueCtx.value.clearRect(0, 0, vueCanvas.value.width, vueCanvas.value.height);
    drawConfetti();
}

function shootConfetti() {
    requestAnimationFrame(shootConfetti);
}
</script>

<style scoped>
#canvas-confetti {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
}
</style>